import { useQuery } from "@tanstack/react-query";
import { AjaxResponse } from "../../_core/api/types";
import { getIdentityUser, doesHubUserExist, getUserpoolPasswordPolicy } from "../api";
import { IdentityUser } from "../api/types";
import { PasswordPolicy } from "../types";

export function useIdentityUser(options?: any) {
  return useQuery<IdentityUser | null, AjaxResponse>({
    queryKey: ["identityUser"],
    queryFn: getIdentityUser,
    ...options,
  });
}

export function useHubUserExist(options?: any) {
  return useQuery<boolean, AjaxResponse>({
    queryKey: ["hubUserExist"],
    queryFn: doesHubUserExist,
    ...options,
  });
}

export const useCognitoUserpoolPolicy = (options?: any) => {
  const userPoolId = extractUserPoolId(window.authConfig.tokenIssuerUrl);

  return useQuery<PasswordPolicy, AjaxResponse>({
    queryKey: ["cognitoUserpoolPolicy", userPoolId],
    queryFn: () => getUserpoolPasswordPolicy(userPoolId),
    enabled: Boolean(userPoolId),
    ...options
  });
};

export const extractUserPoolId = (url: string) => {
  const parts = url.split("/");

  return parts[parts.length - 1];
};