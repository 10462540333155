import CognitoHeader from "../components/CognitoHeader";
import CognitoFooter from "../components/CognitoFooter";
import { useNavigate } from "react-router-dom";
import UIAlert from "../../_core/components/ui/UIAlert";
import UIButton from "../../_core/components/ui/UIButton";
import { UIBox, UIGrid } from "../../_core/components";
import UICard from "../../_core/components/ui/UICard";

interface StatusMessageProps {
  type: "success" | "error";
  message: string;
  onConfirm?: () => void;
}

const StatusMessage = ({ type, message, onConfirm }: StatusMessageProps) => {
  const navigate = useNavigate();

  const handleConfirm = onConfirm ?? (() => navigate("/login"));

  return (
    <UIGrid width="480px">
      <CognitoHeader />
      <UICard sx={{ borderRadius: 0 }}>
        <UIBox component="div" display="flex" flexDirection="column" alignItems="stretch" gap="1rem">
          <UIAlert severity={type}>
            {message}
          </UIAlert>
          <UIButton onClick={handleConfirm}>Go to login page</UIButton>
        </UIBox>
      </UICard>
      <CognitoFooter />
    </UIGrid>
  );
};

export default StatusMessage;
