import { useBrandWelcomeText, useLogo } from "../../brand/hooks";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const CognitoHeader = () => {
  const classes = useStyles();
  const welcomeText = useBrandWelcomeText();
  const logo = useLogo();

  return (
    <div className={classes.header} style={{ height: logo ? 200 : 100 }}>
      {Boolean(logo) && <img alt="logo" src={logo} data-testid="logo" />}
      <Typography variant="h3" textAlign="center" data-testid="welcome-title">
        {welcomeText}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "inherit",
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    "& > img": {
      display: "block",
      width: "70%",
      height: "60%",
      objectFit: "contain",
    },
  },
}));

export default CognitoHeader;
