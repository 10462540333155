import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate, useLocation } from "react-router-dom";
import Main from "../../_core/components/layout/Main";
import UITable from "../../_core/components/ui/UITable";
import { useAccounts } from "../hooks";
import UIButton from "../../_core/components/ui/UIButton";
import { useTableControls } from "../../_core/hooks";
import UISearch from "../../_core/components/ui/UISearch";

const Accounts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchParam = queryParams.get("search");
  const [search, setSearch] = useState(queryParams.get("accountCode") || searchParam || "");
  const { page, rowsPerPage, onTableChange, ...controls } = useTableControls(
    parseInt(queryParams.get("page") ?? "0"),
    parseInt(queryParams.get("limit") ?? "25")
  );

  const { data, isFetching } = useAccounts(
    {
      page: page,
      size: rowsPerPage,
      search,
    },
    { enabled: Boolean(search) }
  );

  const onSearchChange = (ev: any) => {
    const val = ev?.target?.value;
    const searchParams = new URLSearchParams(location.search);
    if (val) {
      setSearch(val);
      searchParams.set("search", val);
      searchParams.delete("accountId");
      searchParams.delete("accountCode");
      searchParams.delete("page");
    } else {
      searchParams.delete("search");
    }
    navigate("?" + searchParams.toString());
  };

  const handlePageChange = (page: number) => {
    onTableChange("changePage", { page });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.toString());
    navigate("?" + searchParams.toString());
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    onTableChange("changeRowsPerPage", { rowsPerPage });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("limit", rowsPerPage.toString());
    navigate("?" + searchParams.toString());
  };

  return (
    <Main data-testid="accounts-container" title="Accounts">
      <div className={classes.root}>
        <UISearch
          value={search}
          onChange={onSearchChange}
          debounceMs={600}
          isLoading={isFetching && Boolean(search)}
          className={classes.search}
        />
        <UITable
          className={classes.table}
          isLoading={isFetching}
          columns={["Account ID", "Name", ""]}
          data={searchParam?.length ? data?.content : []}
          rowCallback={(acc) => [
            acc.code,
            acc.name,
            <div className={classes.view}>
              <UIButton
                onClick={() =>
                  navigate({
                    pathname: "/users",
                    search: `accountId=${acc.id}&accountCode=${acc.code}&level=${acc.level}`,
                  })
                }
              >
                View
              </UIButton>
            </div>,
          ]}
          options={{
            ...controls,
            count: searchParam?.length ? data?.totalElements : 0,
            page: page,
            rowsPerPage: rowsPerPage,
            onChangePage: handlePageChange,
            onChangeRowsPerPage: handleRowsPerPageChange,
          }}
        />
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(3),
  },
  search: {
    width: "50% !important",
  },
  view: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default Accounts;
