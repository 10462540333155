import {AjaxError, AjaxResponse} from "../../_core/api/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  assignUser,
  createUser,
  getExistingUser,
  getUsers,
  resendWelcomeEmail,
} from "../api";
import {
  UserAccountParams,
  CreateUserParams,
  ExistingUser,
  GetExistingUserResp,
  GetUsersParams,
  GetUsersResp,
  UserLite,
} from "../api/types";

export function useUsers(params: GetUsersParams, options?: any) {
  return useQuery<GetUsersResp, AjaxError>({
    queryKey: ["users", params],
    queryFn: () => getUsers(params),
    ...options,
  });
}

export function useExistingUser(email: string, options?: any) {
  return useQuery<GetExistingUserResp | null, AjaxError>({
    queryKey: ["existingUser", email],
    queryFn: () => getExistingUser(email),
    ...options,
  });
}

export function useCreateUser(options?: any) {
  const queryClient = useQueryClient();
  return useMutation<string, AjaxError, CreateUserParams>({
    queryKey: ["createUser"],
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    ...options,
  });
}

export function useAssignUser(options?: any) {
  const queryClient = useQueryClient();
  return useMutation<ExistingUser, AjaxError, UserAccountParams>({
    queryKey: ["assignUser"],
    mutationFn: assignUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    ...options,
  });
}

export function useResendWelcomeEmail(options?: any) {
  return useMutation<AjaxResponse, AjaxError, UserAccountParams>({
    queryKey: ["welcomeEmail"],
    mutationFn: resendWelcomeEmail,
    ...options,
  });
}
