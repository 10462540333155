import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_core/components/layout/Main";
import UITable from "../../_core/components/ui/UITable";
import UIButton from "../../_core/components/ui/UIButton";
import {useApiAlerts, useTableControls} from "../../_core/hooks";
import UIBack from "../../_core/components/ui/UIBack";
import { formatDate, humanize } from "../../_core/utils";
import { useResendWelcomeEmail, useUsers } from "../hooks";
import { AccountLevel, accountLevelToString, UserLite } from "../api/types";
import { impersonateHubUser, isCognitoUser } from "../../auth/api/utils";
import { useIdentityUser } from "../../auth/hooks";

const Users = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const controls = useTableControls();
  const cognitoUser = isCognitoUser();
  const { data: identityUser } = useIdentityUser();
  const [impersonatingUuid, setImpersonatingUuid] = useState<string | null>(null);
  const [impersonatingError, setImpersonatingError] = useState<any>();
  const queryParams = new URLSearchParams(location.search);
  const accountId = queryParams.get("accountId") ?? (() => { throw new Error("accountId is required here"); })();
  const accountCode = queryParams.get("accountCode");
  const accountLevel = queryParams.get("level");

  if (!accountId || !accountCode) {
    navigate(accountCode ? `/?search=${accountCode}` : "/");
  }

  const { data, isFetching, isError, error } = useUsers(
    {
      page: controls.page,
      size: controls.rowsPerPage,
      accountId: accountId || "",
    },
    { enabled: Boolean(accountId) }
  );

  const { mutate: executeResendEmail, isSuccess: isEmailSuccess, error: emailError, isError: isEmailError } = useResendWelcomeEmail();

  useApiAlerts(
    isEmailSuccess ? "Welcome email sent" : undefined,
    error || emailError || impersonatingError,
    isError || isEmailError || !!impersonatingError
  );

  const handleImpersonate = async (targetUser: UserLite) => {
    setImpersonatingUuid(targetUser.id);
    try {
      await impersonateHubUser(targetUser, identityUser);
    } catch (e) {
      setImpersonatingError(e);
    } finally {
      setImpersonatingUuid(null);
      setImpersonatingError(null);
    }
  };

  return (
    <Main
      title={`Users (${accountCode})`}
      data-testid="users-container"
      action={{
        label: "Add User",
        disabled: accountLevel === AccountLevel.SubAccount,
        tooltipText: accountLevel === AccountLevel.SubAccount ? "You are unable to create a sub-account user in this portal." : "",
        startIcon: <AddIcon />,
        onClick: () => navigate("/users/new", { state: { accountId, accountCode } }),
      }}
    >
      <div className={classes.root}>
        <UIBack label="Back To Accounts" />
        <UITable
          className={classes.table}
          isLoading={isFetching}
          columns={["Name", "Email", "User Access", "Last Activity", "Validated", "Status", "", ""]}
          data={data?.content}
          rowCallback={(u) => [
            u.name,
            u.email,
            u.userAccess.map((accountLevel: AccountLevel) => accountLevelToString[accountLevel] || "Unknown").join(", "),
            formatDate(u.lastActivity),
            u.validated ? "Yes" : "No",
            humanize(u.status || ""),
            u.validated ? (
              " "
            ) : (
              <UIButton data-testid="resend-btn" onClick={() => executeResendEmail({userId: u.id, accountId})} className={classes.resend}>
                Resend Welcome Email
              </UIButton>
            ),
            <UIButton
              data-testid="impersonate-btn"
              color="secondary"
              isLoading={impersonatingUuid === u.id}
              disabled={impersonatingUuid === u.id}
              onClick={() => handleImpersonate(u)}
            >
              Masquerade
            </UIButton>,
          ]}
          options={{
            ...controls,
            count: data?.totalElements,
          }}
        />
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 8,
    "& table tr td:last-child": {
      textAlign: "right",
    },
  },
  table: {
    paddingTop: 22,
  },
  resend: {
    minWidth: 180,
  },
}));

export default Users;
