import { AxiosResponse } from "axios";
import api from "../../_core/api";
import {
  UserAccountParams,
  CreateUserParams,
  ExistingUser,
  GetExistingUserResp,
  GetHubUserJwtResp,
  GetUsersParams,
  GetUsersResp,
  User,
  UserLite,
} from "./types";

export const getUsers = async (parameters: GetUsersParams) => {
  const { accountId, ...params } = parameters;
  const { data }: { data: GetUsersResp } = await api({
    method: "GET",
    url: `/users/accounts/${accountId}`,
    params,
  });
  return data;
};

export const getExistingUser = async (email: string) => {
  try {
    const { data }: AxiosResponse<GetExistingUserResp> = await api({
      method: "GET",
      url: `/users/details/${email}`,
    });
    return data;
  } catch (error: any) {
    if (error?.status !== "NOT_FOUND") console.error(error);
    return null;
  }
};

export const createUser = async (parameters: CreateUserParams) => {
  const { accountId, ...params } = parameters;
  const { data }: { data: string } = await api({
    method: "POST",
    url: `/users/accounts/${accountId}`,
    data: params,
  });
  return data;
};

export const assignUser = async ({ accountId, userId }: UserAccountParams) => {
  const { data }: { data: ExistingUser } = await api({
    method: "PUT",
    url: `/users/${userId}/accounts/${accountId}`,
  });
  return data;
};

export const updateMyUser = async (user: User) => {
  const { data } = await api({
    method: "PUT",
    url: `/users/my`,
    data: {
      ...user,
    },
  });
  return data;
};

export const resendWelcomeEmail = async ({ accountId, userId }: UserAccountParams) => {
  return await api({
    method: "POST",
    url: `/users/${userId}/resend-email`,
    params: { "account-id": accountId },
  });
};

export const getHubUserJwt = async (user: UserLite) => {
  const { data }: { data: GetHubUserJwtResp } = await api({
    method: "GET",
    url: `/users/${user.id}/impersonate`,
  });
  return data;
};
