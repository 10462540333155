import { initAmplify } from "../../_core/lib/amplify";
import { getAuthConfig } from "../api";
import { setupIdentity } from "../api/identityAPI";
import { isCognitoUser } from "../api/utils";

import { Fragment } from "react/jsx-runtime";
import { PasswordPolicy } from "../types";
import { z } from "zod";
import { createElement } from "react";

export const createPasswordSchema = (passwordPolicy: PasswordPolicy | undefined) => {
  if (passwordPolicy === undefined) {
    return z.object({
      password: z.string(),
      confirmPassword: z.string(),
    });
  }

  let schema = z
    .string()
    .min(passwordPolicy.minimumLength, `Password must be at least ${passwordPolicy.minimumLength} characters long`);

  if (passwordPolicy.requireLowercase) {
    schema = schema.regex(/[a-z]/, "Password must contain at least one lowercase letter");
  }
  if (passwordPolicy.requireUppercase) {
    schema = schema.regex(/[A-Z]/, "Password must contain at least one uppercase letter");
  }
  if (passwordPolicy.requireNumbers) {
    schema = schema.regex(/[0-9]/, "Password must contain at least one number");
  }
  if (passwordPolicy.requireSymbols) {
    schema = schema.regex(
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      `Password must contain at least one special character:\n
     ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + =`,
    );
  }

  return z
    .object({
      password: schema,
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords must match",
      path: ["confirmPassword"],
    });
};

export const formatMultilineErrorMessage = (message?: string) => {
  if (!message) return null;

  const lines = message.split("\n");

  if (lines.length < 2) return message;

  return createElement(
    Fragment,
    {},
    lines.map((line, i) =>
      createElement(
        Fragment,
        { key: `line-${i}` },
        createElement("span", {}, line),
        i < lines.length - 1 ? createElement("br") : null,
      ),
    ),
  );
};

export async function setupAuth() {
  try {
    const authConfig = await getAuthConfig();
    window.authConfig = authConfig;
    if (isCognitoUser()) {
      initAmplify(authConfig);
    } else {
      setupIdentity(authConfig.partnerPortalClientId, authConfig.tokenIssuerUrl);
    }
    return;
  } catch (error) {
    throw error;
  }
}
