import { makeStyles, useTheme } from "@mui/styles";
import { usePoweredByLogo } from "../../brand/hooks";

const CognitoFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const poweredBy = usePoweredByLogo();

  return (
    <div className={classes.footer} style={{ height: poweredBy ? 100 : 0 }}>
      {Boolean(poweredBy) && <img alt="powered-by-logo" src={poweredBy} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "inherit",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),

    "& > img": {
      display: "block",
      width: "70%",
      height: "100%",
      objectFit: "contain",
    },
  },
}));

export default CognitoFooter;
