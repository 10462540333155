import React, { ReactNode } from "react";
import { useLoginBackground } from "../../brand/hooks";
import { makeStyles, useTheme } from "@mui/styles";
import Public from "./Public";

interface AuthLayoutProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  title: string;
  className?: string;
  style?: React.CSSProperties;
}

const AuthLayout = ({ children, title, className, style, ...props }: AuthLayoutProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const bgImage = useLoginBackground();

  return (
    <Public
      title={title}
      className={`${classes.ctr} ${className}`}
      style={{
        backgroundImage: `url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.tertiary.dark,
        ...style,
      }}
      {...props}
    >
      {children}
    </Public>
  );
};

const useStyles = makeStyles((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",

    "& [data-amplify-authenticator]": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 6,
      border: "none",
    },
    "& [data-amplify-router]": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: "none",
      border: "none",
    },
    "& [data-amplify-form]": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    "& [data-amplify-authenticator] [data-amplify-footer]": {
      paddingBottom: 0,
    },
    "& .amplify-heading": {
      placeSelf: "center",
      fontSize: "1.25rem",
    },
  },
}));

export default AuthLayout;
